import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import { GET_REGULATOR, GET_REGULATORS, STORE_REGULATOR, UPDATE_REGULATOR } from "../store/modules/regulator.module";
export  default {
  methods: {
    storeRegulator(formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(STORE_REGULATOR, formData), actions)
    },
    getRegulator(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_REGULATOR, id), actions)
    },
    updateRegulator(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(UPDATE_REGULATOR, { id, formData }), actions)
    },
    getRegulators(page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_REGULATORS, page), actions)
    },
  }
}
