<template>
  <div class="d-flex kt-wizard-v4" id="kt_wizard_v4" data-ktwizard-state="step-first">
    <!--begin: Form Wizard Nav -->
    <div class="kt-wizard-v4__nav">

      <!--doc: Remove "kt-wizard-v4__nav-items--clickable" class and also set 'clickableSteps: false' in the JS init to disable manually clicking step titles -->
      <div class="kt-wizard-v4__nav-items kt-wizard-v4__nav-items--clickable">
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              1
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                {{ $t("COMPONENTS.FORMS.REGULATOR_FORM.FORM.STEP.ONE.TITLE") }}
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                {{ $t("COMPONENTS.FORMS.REGULATOR_FORM.FORM.STEP.ONE.DESC") }}
              </div>
            </div>
          </div>
        </div>
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              2
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                {{ $t("COMPONENTS.FORMS.REGULATOR_FORM.FORM.STEP.TWO.TITLE") }}
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                {{ $t("COMPONENTS.FORMS.REGULATOR_FORM.FORM.STEP.TWO.DESC") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end: Form Wizard Nav -->
    <portlet body-fit body-class="border-start-0" class="border-start-0">
      <template v-slot:body>
        <div class="kt-grid">
          <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">

            <!--begin: Form Wizard Form-->
            <div class="kt-form" id="kt_form">
              <!--begin: Form Wizard Step 1-->
              <div class="kt-wizard-v4__content other" data-ktwizard-type="step-content">
                <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.REGULATOR_FORM.FORM.STEP.ONE.DESC") }}</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <b-row>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-title-ug" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.title')" label-for="title-ug">
                          <b-form-input id="title-ug" class="direction-rtl" v-model="formData['zh-UG'].title" :state="validateState('title', 'zh-UG')" aria-describedby="title-ug-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.title_ug')" />
                          <b-form-invalid-feedback id="title-ug-live-feedback">
                            {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.title') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-register_authority-ug" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.register_authority')" label-for="register_authority-ug">
                          <b-form-input class="direction-rtl" id="register_authority-ug" v-model="formData['zh-UG'].register_authority" :state="validateState('register_authority', 'zh-UG')" type="text" aria-describedby="register_authority-ug-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.register_authority_ug')" />
                          <b-form-invalid-feedback id="register_authority-ug-live-feedback">
                            {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.register_authority') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-deposit_bank-ug" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.deposit_bank')" label-for="deposit_bank-ug">
                          <b-form-input class="direction-rtl" id="deposit_bank-ug" v-model="formData['zh-UG'].deposit_bank" :state="validateState('deposit_bank', 'zh-UG')" type="text" aria-describedby="deposit_bank-ug-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.deposit_bank_ug')" />
                          <b-form-invalid-feedback id="deposit_bank-ug-live-feedback">
                            {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.deposit_bank') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-address-ug" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.address')" label-for="address-ug">
                          <b-form-input class="direction-rtl" id="address-ug" v-model="formData['zh-UG'].address" :state="validateState('address', 'zh-UG')" type="text" aria-describedby="address-ug-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.address_ug')" />
                          <b-form-invalid-feedback id="address-ug-live-feedback">
                            {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.address') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="12">
                        <b-form-group id="input-group-describe-ug" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.describe')" label-for="describe-ug">
                          <b-form-input class="direction-rtl" id="describe-ug" v-model="formData['zh-UG'].describe" type="text" aria-describedby="describe-ug-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.describe_ug')" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 1-->

              <!--begin: Form Wizard Step 2-->
              <div class="kt-wizard-v4__content other" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.REGULATOR_FORM.FORM.STEP.TWO.DESC") }}</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <b-row>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-title-cn" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.title')" label-for="title-cn">
                          <b-form-input id="title-cn" class="direction-ltr" v-model="formData['zh-CN'].title" :state="validateState('title', 'zh-CN')" aria-describedby="title-cn-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.title_cn')" />
                          <b-form-invalid-feedback id="title-cn-live-feedback">
                            {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.title') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-register_authority-cn" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.register_authority')" label-for="register_authority-cn">
                          <b-form-input class="direction-ltr" id="register_authority-cn" v-model="formData['zh-CN'].register_authority" :state="validateState('register_authority', 'zh-CN')" type="text" aria-describedby="register_authority-cn-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.register_authority_cn')" />
                          <b-form-invalid-feedback id="register_authority-cn-live-feedback">
                            {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.register_authority') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-deposit_bank-cn" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.deposit_bank')" label-for="deposit_bank-cn">
                          <b-form-input class="direction-ltr" id="deposit_bank-cn" v-model="formData['zh-CN'].deposit_bank" :state="validateState('deposit_bank', 'zh-CN')" type="text" aria-describedby="deposit_bank-cn-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.deposit_bank_cn')" />
                          <b-form-invalid-feedback id="deposit_bank-cn-live-feedback">
                            {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.deposit_bank') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-address-cn" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.address')" label-for="address-cn">
                          <b-form-input class="direction-ltr" id="address-cn" v-model="formData['zh-CN'].address" :state="validateState('address', 'zh-CN')" type="text" aria-describedby="address-cn-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.address_cn')" />
                          <b-form-invalid-feedback id="address-cn-live-feedback">
                            {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.address') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="12">
                        <b-form-group id="input-group-describe-cn" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.describe')" label-for="describe-cn">
                          <b-form-input class="direction-ltr" id="describe-cn" v-model="formData['zh-CN'].describe" type="text" aria-describedby="describe-cn-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.describe_cn')" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 2-->

              <div class="kt-wizard-v4__other">
                <b-row>
                  <b-col sm="6" md="2">
                    <b-form-group id="input-group-is_show" :label="$t('COMMON.FORM.LABEL.is_show')" label-for="is_show">
                      <b-form-radio-group
                        id="is_show"
                        v-model="formData.is_show"
                        :options="yesOrNot"
                        buttons />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-credit_code" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.credit_code')" label-for="credit_code">
                      <b-form-input class="direction-rtl" style="width: 100%;" id="credit_code" v-model="formData.credit_code" :state="validateState('credit_code')" aria-describedby="credit_code-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.credit_code')" />
                      <b-form-invalid-feedback id="credit_code-live-feedback">{{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.credit_code') }}</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-bank_account" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.bank_account')" label-for="bank_account">
                      <b-form-input class="direction-rtl" style="width: 100%;" id="bank_account" min="0" v-model="formData.bank_account" type="number" :state="validateState('bank_account')" aria-describedby="bank_account-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.bank_account')" />
                      <b-form-invalid-feedback id="bank_account-live-feedback">{{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.bank_account') }}</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-phone" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.phone')" label-for="phone">
                      <b-form-input class="direction-rtl" style="width: 100%;" id="phone" min="0" v-model="formData.phone" type="number" :state="validateState('phone')" aria-describedby="phone-live-feedback" :placeholder="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.phone')" />
                      <b-form-invalid-feedback id="phone-live-feedback">{{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.phone') }}</b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-9" :label="$t('COMMON.FORM.LABEL.sort')" label-for="sort">
                      <b-form-input style="width: 100%;" id="sort" min="0" v-model="formData.sort" type="number" :placeholder="$t('COMMON.FORM.PLACEHOLDER.sort')" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="3">
                    <b-form-group :state="validateState('logo_image')" aria-describedby="logo_image-live-feedback" id="input-group-logo_image" :description="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.logo_image')" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.logo_image')" label-for="logo_image">
                      <image-uploader v-model="formData.logo_image" :upload-form="{strategy: 'regulator'}" accepted-file-types="image/png, image/jpg, image/jpeg, image/bmp" />
                      <b-form-invalid-feedback id="logo_image-live-feedback">
                        {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.logo_image') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="3">
                    <b-form-group :state="validateState('donation_certificate_image')" aria-describedby="donation_certificate_image-live-feedback" id="input-group-donation_certificate_image" :description="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.donation_certificate_image')" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.donation_certificate_image')" label-for="donation_certificate_image">
                      <image-uploader v-model="formData.donation_certificate_image" :upload-form="{strategy: 'regulator'}" accepted-file-types="image/png, image/jpg, image/jpeg, image/bmp" />
                      <b-form-invalid-feedback id="donation_certificate_image-live-feedback">
                        {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.donation_certificate_image') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="3">
                    <b-form-group :state="validateState('organization_certificate_image')" aria-describedby="organization_certificate_image-live-feedback" id="input-group-organization_certificate_image" :description="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.PLACEHOLDER.organization_certificate_image')" :label="$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.LABEL.organization_certificate_image')" label-for="organization_certificate_image">
                      <image-uploader v-model="formData.organization_certificate_image" :upload-form="{strategy: 'regulator'}" accepted-file-types="image/png, image/jpg, image/jpeg, image/bmp" />
                      <b-form-invalid-feedback id="organization_certificate_image-live-feedback">
                        {{ $t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.FEEDBACK.organization_certificate_image') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <!--begin: Form Actions -->
              <div class="kt-form__actions">
                <button class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-prev">
                  {{ $t("COMMON.FORM.BUTTONS.PREV") }}
                </button>
                <button class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-submit" @click="onSubmit">
                  {{ $t("COMMON.FORM.BUTTONS.SUBMIT") }}
                </button>
                <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                  {{ $t("COMMON.FORM.BUTTONS.NEXT") }}
                </button>
              </div>
              <!--end: Form Actions -->
            </div>
            <!--end: Form Wizard Form-->
          </div>
        </div>
      </template>
    </portlet>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import ImageUploader from "@v@/partials/layout/ImageUploader";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import regulatorMixin from "@m@/regulator.mixin"
  import KTWizard from "@/assets/js/wizard";
  import KTUtil from "@/assets/js/util";
  export default {
    name: "index",
    components: {Portlet, ImageUploader},
    props: {
      action: {
        type: String,
        default: 'create'
      },
      id: {
        type: [String, Number],
      },
    },
    mixins: [regulatorMixin, validateStateMixin, validationMixin, sweetAlertMixin],
    created() {
      if (this.action === 'edit'){
        this.getRegulator(this.id)
          .then(res=>{
            this.formData = Object.assign({}, this.formData, res.data);
          })
          .catch(err=>{
            console.info('getRegulator.err: ', err);
          })
      }
    },
    mounted() {
      this.wizardHandler();
    },
    validations(){
      let validates = {
        phone: { required },
        bank_account: { required },
        credit_code: { required },
        logo_image: { required },
        donation_certificate_image: { required },
        organization_certificate_image: { required },
      };
      validates['zh-UG'] = {
        title: { required },
        address: { required },
        deposit_bank: { required },
        register_authority: { required },
      };
      validates['zh-CN'] = {
        title: { required },
        address: { required },
        deposit_bank: { required },
        register_authority: { required },
      };
      return {
        formData: validates
      }
    },
    data() {
      return {
        loading: false,
        yesOrNot: [
          { text: this.$t('COMMON.FORM.OPTIONS.YES'), value: true },
          { text: this.$t('COMMON.FORM.OPTIONS.NO'), value: false },
        ],
        formData: {
          phone: null,
          bank_account: null,
          credit_code: null,
          logo_image: null,
          donation_certificate_image: null,
          organization_certificate_image: null,
          sort: null,
          is_show: true,
          "zh-UG": {
            title: null,
            address: null,
            deposit_bank: null,
            register_authority: null,
            language: 'zh-UG',
            describe: null
          },
          "zh-CN": {
            title: null,
            address: null,
            deposit_bank: null,
            register_authority: null,
            language: 'zh-CN',
            describe: null
          }
        },
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: this.$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.TIPS.VALIDATION_TIPS')});
        }
        if (this.action === 'create'){
          this.storeRegulator(this.formData)
            .then(()=> {
              this.$router.replace({ name: `regulator.home` });
            }).catch(err=>{
              console.info('storeRegulator.err: ', err)
            })
        }else if ( this.action === 'edit' ){
          delete this.formData.lang;
          this.updateRegulator(this.id, this.formData)
            .then(()=>{
              this.success({ message: this.$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.TIPS.EDIT_SUCCESS')})
            })
            .catch(err=>{
              console.info('updateRegulator.err: ', err)
            })
        }
      },
      wizardHandler(){
        // Initialize form wizard
        this.wizard = new KTWizard('kt_wizard_v4', {
          startStep: 1, // initial active step number
          clickableSteps: true  // allow step clicking
        });
        this.$nextTick(function () {
          const self = this;
          // Validation before going to next page
          this.wizard.on('beforeNext', function(wizardObj) {
            // console.info('beforeNext.wizardObj: ', wizardObj);
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          this.wizard.on('beforePrev', function(wizardObj) {
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          // Change event
          this.wizard.on('change', function(wizard) {
            KTUtil.scrollTop();
          });
        });
      },
      validHandler(formName = 'title', wizardObj){
        if( formName ){
          this.$v.formData[formName].$touch();
        }else {
          this.$v.formData.$touch();
        }
        // if (this.$v.formData[formName].$anyError === true) {
        if (this.$v.formData.$anyError === true) {
          wizardObj.stop();
          this.warning({message: this.$t('COMPONENTS.FORMS.REGULATOR_FORM.FORM.TIPS.VALIDATION_TIPS')});
          return false;
        }else {
          return true;
        }
      },
      stepValidHandler(currentStep, wizardObj){
        switch (currentStep) {
          case 1:
            this.validHandler("zh-UG", wizardObj);
            break;
          case 2:
            this.validHandler("zh-CN", wizardObj);
            break;
        }
      },
    }
  }
</script>

<style lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-4";
</style>
